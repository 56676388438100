import React from "react";
import styles from "./styles.module.scss";
import { SideBar } from "../../components/BlockPost/SideBar/SideBar";
import { kydasTeamWinPrewiew, regionalHacaton, skillfactory } from "../../assets";
import dateIcom from "../../assets/Calendar.svg"
import { ReadAboutUs } from "../../components/BlockPost/ReadAboutUs/ReadAboutUs";


export const SkillFactoryAI = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  const cloud = [
    { link: "https://media.contented.ru/opyt/istorii-studentov/chat-bot-v-stile-retrokiberpank/", text: "Подробнее о проекте на сайте Skillfactory" },


  ];
  const cards = [{
    imageUrl: kydasTeamWinPrewiew,
    date: "12 июня 2023",
    title: "Победили на\u00A0крупнейшем хакатоне России",
    link: "/blog/hacatonWin",
    sidebarTable: true,
  }, {
    imageUrl: regionalHacaton,
    date: "18 ноября 2023",
    title: "Заняли призовое место на\u00A0региональном хакатоне",
    link: "/blog/regionalHacaton",
    sidebarTable: true,
  },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.contentBlock}>
        <div className={styles.content}>
          <div className={styles.imageBlockMain}>

            <picture >


              <img className={styles.img} src={skillfactory} alt={""} />
            </picture>
          </div>
          <div /* style={{ marginBottom: "32x", marginTop: "0px" }}  */className={styles.date}> <img src={dateIcom} alt="" /> 23 сентября 2023</div>
          <h1 className={styles.h1}>Kydas&nbsp;x&nbsp;Skillfactory провели мероприятие AI Revolution
          </h1>
          <p className={styles.text}>
            Онлайн-школа &laquo;‎Skillfactory&raquo; пригласила нашу команду провести мероприятие, посвящённое искусственному интеллекту &laquo;AI&nbsp;Revolution&raquo;. Староста Кирилл и&nbsp;Александр Коновалов выступили в&nbsp;роли менторов для студентов.

          </p>

          <p className={styles.text}>
            23&nbsp;сентября Кирилл и&nbsp;Александр представили студентам вебинары по&nbsp;темам &laquo;ИИ&nbsp;для дизайнеров&raquo; и&nbsp;&laquo;ИИ&nbsp;для разработчиков&raquo;. После презентаций было также анонсировано тематическое мероприятие, суть которого заключалась в&nbsp;разработке чата для креативных агентств.
          </p>


          <p className={styles.text}>
            Задачей нашей команды было консультирование студентов и&nbsp;подсказки во&nbsp;время разработки. Совместными усилиями проект был завершён и&nbsp;опубликован для партнёров &laquo;‎Skillfactory&raquo;.
          </p>

          <div>
            <ReadAboutUs cloud={cloud} />
          </div>
        </div>
        <SideBar cards={cards} />
      </div>
    </div>
  );
};
