import React from "react";

import { Banner } from "../../components/MainPage/Banner/Banner";
import { PreviewBlock } from "../../components/MainPage/PreviewBlock/PreviewBlock";
import styles from "./MainPage.module.scss"
import { useNavigate } from "react-router-dom";
import { kydasTeamWinPrewiew, regionalHacaton, skillfactory } from "../../assets";

export const MainPage = () => {
  const navigate = useNavigate()
  const cards = [{
    imageUrl: kydasTeamWinPrewiew,
    date: "12 июня 2023",
    title: "Победили на\u00A0крупнейшем хакатоне России",
    link: "/blog/hacatonWin",
    sidebarTable: true,
  }, {
    imageUrl: skillfactory,
    date: "23 сентября 2023",
    title: "Kydas x Skillfactory провели мероприятие AI Revolution",
    link: "/blog/SkillFactoryAI",
    sidebarTable: true,
  },
  {
    imageUrl: regionalHacaton,
    date: "18 ноября 2023",
    title: "Заняли призовое место на\u00A0региональном хакатоне",
    link: "/blog/regionalHacaton",
    sidebarTable: true,
  }]
  return (
    <div className={styles.container}>
      <Banner />
      <div className={styles.aboutUs}>
        <div className={styles.header}>Наша история <button onClick={() => navigate("/blog")} className={styles.readAboutUs}>Читать</button></div>
      </div>
      <div className={styles.previeBlock}>
        <PreviewBlock cards={cards} />
      </div>
    </div>
  );
};
