import React from "react";
import styles from "./styles.module.scss";
import { SideBar } from "../../components/BlockPost/SideBar/SideBar";
import { kydasTeamWin1, kydasTeamWin2, regionalHacaton, kydasTeamWinPrewiew, skillfactory, purpleHack } from "../../assets";
import dateIcom from "../../assets/Calendar.svg"
import { ReadAboutUs } from "../../components/BlockPost/ReadAboutUs/ReadAboutUs";
export const HahatonWin = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  const cloud = [
    { link: "https://www.mos.ru/news/item/126419073/", text: "Мэр Москвы" },

    {
      link: "https://www.techinsider.ru/editorial/1603339-ponyat-megapolis-kak-hakaton-lidery-cifrovoy-transformacii-gotovit-razrabotchikov-cifrovyh-resheniy-dlya-goroda/",
      text: "Техинсайдер",
    },

    {
      link: "https://www.comnews.ru/content/227364/2023-07-11/2023-w28/regionov-stolicu-i-obratno-transfer-intellektualnogo-resursa-ramkakh-khakatona-lidery-cifrovoy-transformacii",
      text: "ComNews",
    },
    {
      link: "https://news.ru/press-relizy/hakaton-lidery-cifrovoj-transformacii-obedinil-uchastnikov-so-vsej-rossii/",
      text: "News",
    },
    {
      link: "https://www.mos.ru/news/item/126042073/",
      text: "Организаторы хакатона",
    },
  ];
  const cards = [{
    imageUrl: regionalHacaton,
    date: "18 ноября 2023",
    title: "Заняли призовое место на\u00A0региональном хакатоне",
    link: "/blog/regionalHacaton",
    sidebarTable: true,
  }, {
    imageUrl: skillfactory,
    date: "23 сентября 2023",
    title: "Kydas x Skillfactory провели мероприятие AI Revolution",
    link: "/blog/SkillFactoryAI",
    sidebarTable: true,
  }, {
    imageUrl: purpleHack,
    date: "15 марта 2024",
    title: "Kydas x IT Purple HACK",
    link: "/blog/PurpleHacaton",
    sidebarTable: true,
  }]
  return (
    <div className={styles.container}>
      <div className={styles.contentBlock}>
        <div className={styles.content}>
          <div className={styles.imageBlockMain}>
            <picture >
              <source className={styles.img} media="(min-width: 320px) and (max-width: 779px)" srcSet={kydasTeamWinPrewiew} />

              <source className={styles.img} media="(min-width: 780px) and (max-width: 1279px)" srcSet={kydasTeamWin1} />

              <img className={styles.img} src={kydasTeamWin1} alt="" />
            </picture>
          </div>
          <div className={styles.date}> <img src={dateIcom} alt="" /> 12 июня 2023</div>
          <h1 className={styles.h1}>Победили на&nbsp;крупнейшем хакатоне России</h1>
          <p className={styles.text}>
            Наша команда приняла участие в&nbsp;хакатоне &laquo;Лидеры цифровой трансформации 2023&raquo;. Для нас это был первый конкурс командной разработки. Мы&nbsp;захотели проверить свои силы в&nbsp;создании сложных проектов, поэтому приняли решение зарегистрироваться на&nbsp;это уникальное событие.

          </p>

          <p className={styles.text}>
            Этот хакатон является ежегодным и&nbsp;одним из&nbsp;самых крупных в&nbsp;России. Мы&nbsp;попали на&nbsp;его пятилетний юбилей. Со&nbsp;слов организаторов, в&nbsp;этом году он&nbsp;имел внушительный масштаб, что сделало его ещё самым большим хакатоном в&nbsp;мире. Призовой фонд составил 42&nbsp;млн. рублей. На&nbsp;выбор была представлена 21&nbsp;задача, в&nbsp;том числе от&nbsp;государства и&nbsp;коммерческих компаний, таких как &laquo;Ozon&raquo;, &laquo;Самолет&raquo;, &laquo;Whoosh&raquo; и&nbsp;другие.

          </p>
          <p className={styles.text}>
            Наш выбор пал на&nbsp;четвёртую задачу, суть которой состояла в&nbsp;разработке сервиса обработки обратной связи пользователей &laquo;Московского постамата&raquo;. В&nbsp;сервисе было необходимо структурировать большой объём поступающей информации от&nbsp;пользователей. Также необходимо было самостоятельно обучить и&nbsp;интегрировать в&nbsp;сервис нейросеть для анализа и&nbsp;обработки данных.

          </p>
          <div className={styles.imageBlock}>
            <img className={styles.img} src={kydasTeamWin2} alt="" />
          </div>
          <h2 className={styles.h2}>Как это было</h2>
          <p className={styles.text}>
            В&nbsp;течение десяти дней, наша команда в&nbsp;составе Дмитрия Дроздова, Старосты Кирилла и&nbsp;Александра Коновалова решала поставленную задачу. Нам удалось пройти в&nbsp;список десяти лучших команд и&nbsp;получить приглашение в&nbsp;Москву на&nbsp;участие в&nbsp;финале, которое проходило в&nbsp;кластере &laquo;Ломоносов&raquo;, являющегося частью Инновационного научно-технологического центра МГУ &laquo;Воробьевы горы&raquo;. Тут нам предстояло публично выступить и&nbsp;защитить наш проект, а&nbsp;после дождаться объявления победителей.

          </p>
          <p className={styles.text}>
            12&nbsp;июня 2023 года наша команда &laquo;Kydas&raquo; заняла первое место и&nbsp;получила денежный приз в&nbsp;размере 1&nbsp;млн. рублей.
          </p>
          <p className={styles.text}>
            Хотелось&nbsp;бы отметить высокий уровень организации мероприятия. Этот хакатон подарил нам массу новых приятных впечатлений. Мы&nbsp;рады, что нашим первым хакатоном стал ЛЦТ2023.
          </p>
          <div>
            <ReadAboutUs cloud={cloud} />
          </div>
        </div>
        <SideBar cards={cards} />
      </div>
    </div>
  );
};
