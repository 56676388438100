import React from "react";

import { MainPage } from "./pages/MainPage/MainPage";
import { HahatonWin } from "./pages/BlogPost/HahatonWin";
import { Header } from "./components/common/Header/Header";
import { Routes, Route } from "react-router-dom";
import { RegionalHacaton } from "./pages/BlogPost/RegionalHacaton";
import { BlogPage } from "./pages/BlogPage/BlogPage";
import { PurpleHack } from "./pages/BlogPost/PurpleHack";
import { SkillFactoryAI } from "./pages/BlogPost/SkillFactoryAI";
function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/hacatonWin" element={<HahatonWin />} />
        <Route path="/blog/regionalHacaton" element={<RegionalHacaton />} />
        <Route path="/blog/PurpleHacaton" element={<PurpleHack />} />
        <Route path="/blog/SkillFactoryAI" element={<SkillFactoryAI />} />
      </Routes>
    </div>
  );
}

export default App;
