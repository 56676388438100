import React from "react";
import styles from "./Banner.module.scss";
import bgTablet from "./../../../assets/BackgroundTable.png"
export const Banner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.header}>
          Решаем задачи бизнеса <br />
          с помощью технологий <br />
          и творчества
        </h1>
        <p className={styles.text}>
          Наша команда сфокусирована на&nbsp;создании новых продуктов для&nbsp;автоматизации и&nbsp;оптимизации  <span style={{ whiteSpace: 'nowrap' }}>IT-процессов</span> бизнеса
        </p>
      </div>
      <div className={styles.bgTablet}><img src={bgTablet} alt="" /></div>
    </div>
  );
};
