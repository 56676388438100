import React from "react";
import PostPreviewCard from "../../MainPage/PreviewBlock/PreviewPost/PreviewPost";
import styles from "./SideBar.module.scss";

export const SideBar = ({ cards, isTable }: { cards: any; isTable?: boolean }) => {
  const cardArray = cards.map((cards: any) => (
    <PostPreviewCard
      imageUrl={cards.imageUrl}
      date={cards.date}
      title={cards.title}
      link={cards.link}
      sidebarTable={cards.sidebarTable}

    />


  ))

  return (
    <div>
      <div className={styles.container}>
        {!isTable && (<div className={styles.text}>Другие посты</div>)}
        <div className={styles.content}>
          {/* <PostPreviewCard
            imageUrl={kydasTeamWin2}
            date="12 июня 2023"
            title="Победили на&nbsp;крупнейшем
            хакатоне России"
            link="/blog/hacatonWin"
          />
          <PostPreviewCard
            imageUrl={regionalHacaton}
            date="18 ноября 2023"
            title="Заняли призовое место на&nbsp;региональном хакатоне"
            link="/blog/hacatonWin"
          /> */}
          {cardArray}
        </div>
      </div>
    </div>
  );
};
