
import React from 'react';
import styles from './PreviewPost.module.scss';
import { useNavigate } from 'react-router-dom';
import dataIcon from "../../../../assets/Calendar.svg"
import classNames from "classnames";

interface PostPreviewCardProps {
  imageUrl: string;
  date: string;
  title: string;
  link: string;
  sidebarTable?: boolean;
}

const PostPreviewCard: React.FC<PostPreviewCardProps> = ({ imageUrl, date, title, link, sidebarTable }) => {
  const navigate = useNavigate()
  console.log(sidebarTable)
  return (
    <div className={classNames(styles.card, { [styles.sidebar]: sidebarTable })} onClick={() => navigate(link)}>
      <div className={styles.imageContainer}>
        <img className={classNames(styles.image, { [styles.sidebar]: sidebarTable })} src={imageUrl} alt="Post" />
      </div>
      <div className={styles.date}> <img src={dataIcon} alt="" /><span>{date}</span></div>

      <h2 className={styles.title}>{title}</h2>
    </div>
  );
};

export default PostPreviewCard;