import React from "react";
import styles from "./styles.module.scss";
import { SideBar } from "../../components/BlockPost/SideBar/SideBar";
import { kydasKrasnodar1, kydasKrasnodar2, kydasTeamWinPrewiew, purpleHack, regionalHacaton, skillfactory } from "../../assets";
import dateIcom from "../../assets/Calendar.svg"
import { ReadAboutUs } from "../../components/BlockPost/ReadAboutUs/ReadAboutUs";


export const RegionalHacaton = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  const cloud = [
    { link: "https://www.mos.ru/news/item/132447073/", text: "Мэр Москвы" },

    {
      link: "https://admkrai.krasnodar.ru/content/1131/show/717402/",
      text: "Губернатор Краснодарского края",
    },

    {
      link: "(https://mbkuban.ru/news/sostoyalsya-final-pervogo-regionalnogo-khakatona-lidery-tsifrovoy-transformatsii-krasnodarskiy-kray/",
      text: "Портал МСП на Кубани",
    },
  ];
  const cards = [{
    imageUrl: kydasTeamWinPrewiew,
    date: "12 июня 2023",
    title: "Победили на\u00A0крупнейшем хакатоне России",
    link: "/blog/hacatonWin",
    sidebarTable: true,
  },{
    imageUrl: skillfactory,
    date: "23 сентября 2023",
    title: "Kydas x Skillfactory провели мероприятие AI Revolution",
    link: "/blog/SkillFactoryAI",
    sidebarTable: true,
  }, {
    imageUrl: purpleHack,
    date: "15 марта 2024",
    title: "Kydas x IT Purple HACK",
    link: "/blog/PurpleHacaton",
    sidebarTable: true,
  }]
  return (
    <div className={styles.container}>
      <div className={styles.contentBlock}>
        <div className={styles.content}>
          <div className={styles.imageBlockMain}>

            <picture >
              <source className={styles.img} media="(min-width: 320px) and (max-width: 779px)" srcSet={regionalHacaton} />

              <source className={styles.img} media="(min-width: 780px) and (max-width: 1279px)" srcSet={kydasKrasnodar1} />

              <img className={styles.img} src={kydasKrasnodar1} alt={kydasKrasnodar1} />
            </picture>
          </div>
          <div className={styles.date}> <img src={dateIcom} alt="" /> 18 ноября 2023</div>
          <h1 className={styles.h1}>Заняли призовое место
            на&nbsp;региональном хакатоне </h1>
          <p className={styles.text}>
            Наша команда приняла участие в&nbsp;первом региональном хакатоне &laquo;Лидеры цифровой трансформации&raquo; от&nbsp;города Краснодар. Призовой фонд составил 2.5&nbsp;млн. рублей. Было представлено пять задач, в&nbsp;том числе от&nbsp;компании Proscom, которую мы&nbsp;выбрали. Необходимо было разработать сервис онбординга и&nbsp;адаптации сотрудников. На&nbsp;выполнение задачи давалась одна неделя.

          </p>

          <p className={styles.text}>
            В&nbsp;качестве решения команда предоставила прототип сервиса онбординга и&nbsp;адаптации сотрудников с&nbsp;возможностью гибкой настройки планов обучения. Были реализованы: система ролей, обучающая среда, рассылка информации, аналитика и&nbsp;мониторинг. Ключевой особенностью прототипа является полная техническая реализация всех бизнес-процессов, а&nbsp;также проработанный дизайн, обеспечивающий удобство пользования сервисом.
          </p>
          <div className={styles.imageBlock}>
            <img className={styles.img} src={kydasKrasnodar2} alt="" />
          </div>
          <h2 className={styles.h2}>Итоги</h2>
          <p className={styles.text}>
            Нам удалось пройти отбор среди 40&nbsp;команд и&nbsp;попасть в&nbsp;топ-10&nbsp;лучших. С&nbsp;представленным решением мы&nbsp;выступили 17&nbsp;ноября. На&nbsp;следующий день были объявлены победители и&nbsp;призёры.


          </p>
          <p className={styles.text}>
            Фронтенд-разработчик команды Александр Коновалов смог посетить финал в&nbsp;городе Краснодар. По&nbsp;решению судей мы&nbsp;заняли 3&nbsp;место и&nbsp;получили денежный приз в&nbsp;размере 100 тысяч рублей.
          </p>
          <p className={styles.text}>
            Этот хакатон дал нам бесценный опыт гибкого подхода к&nbsp;разработке и&nbsp;к&nbsp;проектированию продуктов.
          </p>
          <div>
            <ReadAboutUs cloud={cloud} />
          </div>
        </div>
        <SideBar cards={cards} />
      </div>
    </div>
  );
};
