import React from "react";
import styles from "./PreviewBlock.module.scss";
import PostPreviewCard from "./PreviewPost/PreviewPost";



export const PreviewBlock = ({ cards }) => {


  const cardArray = cards.map((card) => (
    <PostPreviewCard
      imageUrl={card.imageUrl}
      date={card.date}
      title={card.title}
      link={card.link}

    />

  ))

  return (
    <div className={styles.container}>
      {/*  <div className={styles.header}>Наша история <button onClick={() => navigate("/blog")} className={styles.readAboutUs}>Читать</button></div> */}
      <div className={styles.PreviewBlock}>
      {/*   <PostPreviewCard
          imageUrl={kydasTeamWinPrewiew}
          date="12 июня 2023"
          title="Победили на крупнейшем
          хакатоне России "
          link="/blog/hacatonWin"
        /> */}
    {/*     <PostPreviewCard
          imageUrl={skillfactory}
          date="23 сентября 2023"
          title="Kydas x Skillfactory провели мероприятие AI Revolution "
          link="/blog/SkillFactoryAI"
        />
        <PostPreviewCard
          imageUrl={regionalHacaton}
          date="18 ноября 2023"
          title="Заняли призовое место 
          на&nbsp;региональном хакатоне "
          link="/blog/regionalHacaton"
        /> */}

        {cardArray}

      </div>
      <div className={styles.PreviewTabletBlock}></div>
    </div>
  );
};
