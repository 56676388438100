import React from "react";
import styles from "./styles.module.scss";
import { SideBar } from "../../components/BlockPost/SideBar/SideBar";
import { regionalHacaton, kydasTeamWinPrewiew, purpleHack } from "../../assets";
import dateIcom from "../../assets/Calendar.svg"
import { ReadAboutUs } from "../../components/BlockPost/ReadAboutUs/ReadAboutUs";
export const PurpleHack = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  const cloud = [
    { link: "https://www.youtube.com/watch?v=Gm7ssYVwZlI", text: "Награждение участников" },
  ];
  const cards = [{
    imageUrl: kydasTeamWinPrewiew,
    date: "12 июня 2023",
    title: "Победили на\u00A0крупнейшем хакатоне России",
    link: "/blog/hacatonWin",
    sidebarTable: true,
  }, {
    imageUrl: regionalHacaton,
    date: "18 ноября 2023",
    title: "Заняли призовое место на\u00A0региональном хакатоне",
    link: "/blog/regionalHacaton",
    sidebarTable: true,
  },]
  return (
    <div className={styles.container}>
      <div className={styles.contentBlock}>
        <div className={styles.content}>
          <div className={styles.imageBlockMain}>
            <picture >


              <img className={styles.img} src={purpleHack} alt="" />
            </picture>
          </div>
          <div style={{ marginBottom: "32px" }} className={styles.date}> <img src={dateIcom} alt="" /> 15 марта 2024</div>
          {/*           <h1 className={styles.h1}>Победили на&nbsp;крупнейшем хакатоне России</h1>
 */}          <p className={styles.text}>
            Наша команда приняла участие в&nbsp;хакатоне &laquo;IT&nbsp;Purple Hack&raquo; в&nbsp;кейсе от&nbsp;компании &laquo;Авито&raquo;. В&nbsp;текущей задаче участвовало 56&nbsp;команд. Основной целью являлось создание оптимизированного алгоритма вычисления цены на&nbsp;платные услуги с&nbsp;помощью ценовых матриц.
            Призовой фонд составил 1&nbsp;000&nbsp;000&nbsp;₽, где за&nbsp;первое место команда получала 100&nbsp;000&nbsp;₽, а&nbsp;за&nbsp;второе и&nbsp;третье&nbsp;&mdash; 50&nbsp;000&nbsp;₽ и&nbsp;25&nbsp;000&nbsp;₽.


          </p>

          <p className={styles.text}>
            Хакатон начался 9&nbsp;марта, и&nbsp;уже 14&nbsp;был объявлен стоп-кодинг. Этим&nbsp;же вечером опубликовали список двенадцати финалистов. На&nbsp;следующий день прошедшие команды выступали с&nbsp;презентациями защиты своих решений.

          </p>
          <p className={styles.text}>
            По&nbsp;результатам выступлений и&nbsp;представленных алгоритмов участники жюри определили победителей и&nbsp;объявили топ-8&nbsp;команд, которые будут приглашены на&nbsp;экскурсию в&nbsp;компанию &laquo;Авито&raquo; и&nbsp;получат их&nbsp;фирменный мерч.


          </p>

          <p className={styles.text}>
            Мы&nbsp;заняли неудовлетворительное 7&nbsp;место, но&nbsp;этот хакатон помог нам обратить внимание на&nbsp;свои ошибки и&nbsp;сделать выводы, которые однозначно усилят нашу команду.
          </p>
          <p className={styles.text}>
            По&nbsp;ходу общения с&nbsp;организаторами мы&nbsp;выяснили, что наше решение не&nbsp;было оценено справедливо. Члены жюри не&nbsp;ознакомились с&nbsp;сопровождающей документацией, которую мы&nbsp;предоставили. По&nbsp;этой причине нам не&nbsp;поставили баллы за&nbsp;неё.
          </p>

          <ReadAboutUs textName="Можно посмотреть тут" cloud={cloud} />



        </div>
        <SideBar cards={cards} />
      </div>
    </div>
  );
};
