import React from "react";
import styles from "./ReadAboutUs.module.scss";

interface CloudItem {
  link: string;
  text: string;
}

export const ReadAboutUs: React.FC<{ cloud: CloudItem[], textName?: string }> = ({ cloud, textName }) => {
  const cloudArray = cloud.map((e, index) => (
    <a
      key={index}
      className={styles.cloud}
      href={e.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {e.text}
    </a>
  ));

  return (
    <>
      <div className={styles.header}>{textName ? textName : <>Про нас также можно прочитать&nbsp;тут</>}</div>
      <div className={styles.cloudBlock}>{cloudArray}</div>
    </>
  );
};
