import React from 'react'
import styles from "./styles.module.scss"
import classNames from 'classnames'
import { PreviewBlock } from '../../components/MainPage/PreviewBlock/PreviewBlock';
import { SideBar } from '../../components/BlockPost/SideBar/SideBar';
import { kydasTeamWinPrewiew, purpleHack, regionalHacaton, skillfactory } from '../../assets';
export const BlogPage = () => {
  const [selectedButton, setSelectedButton] = React.useState("All");
  const handleButtonClick = (button: string) => {
    setSelectedButton(button);
  };
  const cards = [{
    imageUrl: kydasTeamWinPrewiew,
    date: "12 июня 2023",
    title: "Победили на\u00A0крупнейшем хакатоне России",
    link: "/blog/hacatonWin",
    sidebarTable: true,
    type: "Hack"
  }, {
    imageUrl: skillfactory,
    date: "23 сентября 2023",
    title: "Kydas x Skillfactory провели мероприятие AI Revolution",
    link: "/blog/SkillFactoryAI",
    sidebarTable: true,
    type: "Ivent"
  },
  {
    imageUrl: regionalHacaton,
    date: "18 ноября 2023",
    title: "Заняли призовое место на\u00A0региональном хакатоне",
    link: "/blog/regionalHacaton",
    sidebarTable: true,
    type: "Hack"
  },
  {
    imageUrl: purpleHack,
    date: "15 марта 2024",
    title: "Kydas x IT Purple HACK",
    link: "/blog/PurpleHacaton",
    sidebarTable: true,
    type: "Hack"
  }]
  const filteredCards = selectedButton === "All"
    ? cards
    : cards.filter(item => item.type === selectedButton);
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.buttonsBlock}>
          <div onClick={() => handleButtonClick("All")} className={classNames(styles.button, { [styles.active]: selectedButton === "All" })}>Все</div>
          <div onClick={() => handleButtonClick("Hack")} className={classNames(styles.button, { [styles.active]: selectedButton === "Hack" })}>Хакатоны</div>
          <div onClick={() => handleButtonClick("Ivent")} className={classNames(styles.button, { [styles.active]: selectedButton === "Ivent" })}>Мероприятия</div>

        </div>
        <div className={styles.postBlock}><PreviewBlock cards={filteredCards} /></div>
        <div className={styles.postTableBlock}><SideBar isTable={true} cards={filteredCards} /></div>

      </div>
    </div>
  )
}
