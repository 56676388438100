import React from "react";
import styles from "./Header.module.scss";
import { logo } from "../../../assets";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import emailIcon from "../../../assets/Mail.svg"
import close from "../../../assets/Close.svg"
import { PopOver } from "../PopOver/PopOver";

export const Header = () => {

  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const [isMenuExiting, setMenuExiting] = React.useState(false);
  const [showPopOver, setShowPopOver] = React.useState(false)

  const handleClick = () => {
    setShowPopOver(true)
    const timer = setTimeout(() => {
      setShowPopOver(false);
    }, 5000);
    return () => clearTimeout(timer);
  };
  const toggleMenu = () => {
    if (isMenuOpen) {
      document.body.style.overflow = "auto";
      setMenuExiting(true);
      setTimeout(() => {
        setMenuExiting(false);
      }, 250);
    } else {
      window.scrollTo({ top: 0 });
      document.body.style.overflow = "hidden";
    }
    setMenuOpen(!isMenuOpen);
  };
  const popOverRef = React.useRef<HTMLDivElement | null>(null);
  const popOverMobRef = React.useRef<HTMLDivElement | null>(null);



  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popOverRef.current && !popOverRef.current.contains(event.target as Node)) {
        setShowPopOver(false);
      }
    };
    const handleClickOutsideMob = (event: MouseEvent) => {
      if (popOverMobRef.current && !popOverMobRef.current.contains(event.target as Node)) {
        setShowPopOver(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('click', handleClickOutsideMob);


    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('click', handleClickOutsideMob);

    };
  }, []);
  const navBar = (
    <>
      <NavLink
        to={"/"}
        className={({ isActive }) =>
          classNames(styles["navbar-item"], {
            [styles.active]: isActive,
          })
        }
      >
        Главная
      </NavLink>
      <NavLink
        to={"/blog"}
        className={({ isActive }) =>
          classNames(styles["navbar-item"], {
            [styles.active]: isActive,
          })
        }
      >
        Блог
      </NavLink>
    </>
  );
  const navBarMob = (
    <>
      <NavLink
        to={"/"}
        className={({ isActive }) =>
          classNames(styles["navbar-item"], {
            [styles.active]: isActive,
          })
        }
        onClick={() => toggleMenu()}
      >
        Главная
      </NavLink>
      <NavLink
        to={"/blog"}
        className={({ isActive }) =>
          classNames(styles["navbar-item"], {
            [styles.active]: isActive,
          })
        }
        onClick={() => toggleMenu()}
      >
        Блог
      </NavLink>
    </>
  );
  const copyToClipboard = () => {
    navigator.clipboard.writeText("mail@kydas.ru");
  };
  return (
    <>
      <div className={styles.content}>
        <div className={styles.container}>
          <NavLink to={"/"} className={styles.logo}>
            <img src={logo} alt="logo" />
          </NavLink>
          <nav className={styles.navbar}>{navBar}</nav>
          <div className={styles.emailBlock} >
            <div ref={popOverRef} className={styles.emailBlick} onClick={() => copyToClipboard()}>
              <div className={styles.email} onClick={() => handleClick()}>
                <img src={emailIcon} alt="" />
                mail@kydas.ru
              </div>
              {showPopOver && <div className={styles.popOver}><PopOver /></div>}

            </div>
            <a href="mailto:mail@kydas.ru" className={styles.emailButton} onClick={(e) => e.preventDefault}>
              Написать нам
            </a>
          </div>
          <div className={styles.burger} onClick={() => toggleMenu()}>

            <div className={styles.burgerButton}>{isMenuOpen ? <img src={close} alt="" /> : "Меню"}</div>

          </div>

        </div>
        <div className={styles.tabletNav}>{navBar}</div>
        {/* {(isMenuOpen || isMenuExiting) && (
        <div className={styles.burgerContainer}>
          <div
            className={classNames(styles.burgerMenuContent, {
              [styles.menuExiting]: isMenuExiting,
            })}
          >
            <div className={styles.burgerMenu}>{navBarMob}</div>
            <div className={styles.emailBlock}>
              <div className={styles.email} onClick={() => copyToClipboard()}>

                <img src={emailIcon} alt="" />
                mail@kydas.ru
              </div>
              <a href="mailto:mail@kydas.ru" className={styles.emailButton}>
                Написать нам
              </a>
            </div>
          </div>
        </div>
      )} */}
      </div>
      {(isMenuOpen || isMenuExiting) && (
        <div className={styles.burgerContainer}>
          <div
            className={classNames(styles.burgerMenuContent, {
              [styles.menuExiting]: isMenuExiting,
            })}
          >
            <div className={styles.burgerMenu}>{navBarMob}</div>
            <div className={styles.emailBlock}>
              <div ref={popOverMobRef} className={styles.email} onClick={() => { copyToClipboard(); handleClick() }}>

                <img src={emailIcon} alt="" />
                mail@kydas.ru
              </div>
              <a href="mailto:mail@kydas.ru" className={styles.emailButton}>
                Написать нам
              </a>
            </div>
          </div>
        </div>
      )}</>
  );
};
